import { FC } from 'react';

import { IntroBlock } from '@ui/slice-machine/slices/common/IntroBlock';

import { DefaultSectionIntroVariation } from '../types';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';

interface Props {
  slice: DefaultSectionIntroVariation;
}

export const DefaultSectionIntro: FC<Props> = (props) => {
  const { slice } = props;
  const {
    position,
    showDivider,
    subtitle,
    title,
    backgroundColor,
    dividerColor,
    dividerWeight,
    width,
    ctaTextLink,
    ctaBackgroundColor,
    ctaTextColor,
    buttonVariant,
    paddingTop,
    paddingBottom,
  } = slice.primary;
  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      <IntroBlock
        title={title}
        position={position}
        subtitle={subtitle}
        dividerColor={dividerColor}
        dividerWeight={dividerWeight}
        showDivider={showDivider}
        ctaTextLink={ctaTextLink}
        ctaBackgroundColor={ctaBackgroundColor}
        ctaTextColor={ctaTextColor}
        buttonVariant={buttonVariant}
      />
    </SliceLayout>
  );
};
