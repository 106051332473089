import { FC } from 'react';

import { DefaultSectionIntro } from './default-slice/DefaultSectionIntro';
import { DefaultSectionIntroVariation, SectionIntroVariations } from './types';

export interface SectionIntroSliceProps {
  slice: SectionIntroVariations;
}

export const SectionIntroSlice: FC<SectionIntroSliceProps> = (props) => {
  const { slice } = props;

  return <DefaultSectionIntro slice={slice as DefaultSectionIntroVariation} />;
};
